import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import HomeManagement from "@/pages/home/HomeManagement";
import AppMain from "@/pages/AppMain";
import UserManagement from "@/pages/user/UserManagement";
import ElementUI from 'element-ui';
import LoginManagement from "@/pages/login/LoginManagement";
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import RoleManagement from "@/pages/role/RoleManagement";
import CompanyManagement from "@/pages/company/CompanyManagement";
import CompanyDetailManagement from "@/pages/company/CompanyDetailManagement";
import JobManagement from "@/pages/companyJob/CompanyJobManagement";
import CompanyJobManagement from "@/pages/companyJob/CompanyJobManagement";

import WxMessageTemplateManagement from "@/pages/message/WxMessageTemplateManagement";
import SmsMessageTemplateManagement from "@/pages/message/SmsMessageTemplateManagement";
import WxUserManagement from "@/pages/wxUser/WxUserManagement";
import EditWxUserManagement from "@/pages/wxUser/EditWxUserManagement";
import AddWxUserManagement from "@/pages/wxUser/AddWxUserManagement";
import DetailWxUserManagement from "@/pages/wxUser/DetailWxUserManagement";

Vue.use(VueRouter);
Vue.use(ElementUI);
// 定义路由配置
const routes = [
    {
        path: '/appMain',
        component: AppMain,
        children: [
            {
                path: 'home',
                component: HomeManagement
            },
            {
                path: 'role',
                component: RoleManagement
            },
            {
                path: 'user',
                component: UserManagement
            },
            {
                path: 'company',
                component: CompanyManagement
            },
            {
                path: 'companyJob',
                component: JobManagement
            },
            {
                path: 'companyJobDetail',
                component: CompanyJobManagement
            },
            {
                path: 'companyDetail',
                component: CompanyDetailManagement
            },
            {
                path: 'wxTemplateMessage',
                component: WxMessageTemplateManagement
            },
            {
                path: 'smsTemplateMessage',
                component: SmsMessageTemplateManagement
            },
            {
                path: 'wxUser',
                component: WxUserManagement
            },
            {
                path: 'editWxUser',
                component: EditWxUserManagement
            },
            {
                path: 'detailWxUser',
                component: DetailWxUserManagement
            },
            {
                path: 'addWxUser',
                component: AddWxUserManagement
            },
            // 默认子路由,用户没有选择则重定向到首页
            {
                path: '',
                redirect: '/appMain/home'
            }
        ]
    },
    {
        path: '/login',
        component: LoginManagement
    },
    {
        //未知路径，跳转到登录页
        path: '*',
        component: LoginManagement
    },
];

const router = new VueRouter({
    routes
});
// 全局前置守卫
router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register']; // 这些页面不需要身份验证
    const requiresAuth = !publicPages.includes(to.path);
    const token = localStorage.getItem('token');
    if (requiresAuth && !token) {
        // 如果没有token并且目标路由需要身份验证，则重定向到登录页面
        next('/login');
    } else {
        // 否则，正常导航
        next();
    }
});
//全局ajax 拦截器
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.data && error.response.data.responseCode === 401) {
            localStorage.clear();
            this.$message.error("过期 请重新登录");
            router.push('/login');
        } else {
            // 返回接口返回的错误信息
            return Promise.reject(error);
        }
    }
);

Vue.config.productionTip = process.env.NODE_ENV == 'development';
new Vue({
    el: '#app',
    render: h => h(App),
    router // 将 router 实例注入到根 Vue 实例中  ,然后在 App.vue 中使用 this.$router就能获取当前路由对象
});
