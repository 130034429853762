<template>
  <div class="home">
    <div id="echarts_pie" class="echarts"></div>
    <div id="echarts_bar" class="echarts"></div>
    <div id="echarts_line" class="echarts"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'HomeManagement',
  data() {
    return {
      myEcharts: null
    }
  },
  methods: {
    initEcharts() {
      var echartsPie = echarts.init(document.getElementById('echarts_pie'))
      var echartsBar = echarts.init(document.getElementById('echarts_bar'))
      var echartsLine = echarts.init(document.getElementById('echarts_line'))
      echartsPie.setOption({
        title: {
          text: '求职者分布图', // 图标标题
        },
        series: [{
          name: '求职者分布图',
          type: 'pie', // 图类型-饼图
          radius: '55%', // 图占容器大小
          data: [ // 饼图数据格式
            {
              value: 235,
              name: '南京',
              itemStyle: {
                color: 'skyblue', // 某一项的颜色
              }
            },
            {
              value: 123,
              name: '上海',
              itemStyle: {
                color: 'orange'
              }
            },
            {
              value: 154,
              name: '杭州',
              itemStyle: {
                color: 'yellowgreen'
              }
            },
            {
              value: 88,
              name: '湖北',
              itemStyle: {
                color: 'pink'
              }
            }
          ],
          itemStyle: {
            normal: {
              shadowBlur: 20, // 阴影散发长度
              shadowColor: 'rgba(0, 0, 0, 0.5)', // 阴影颜色
              label: {
                show: true, // 是否显示标识文字
                fontSize: 16, // 字体大小，单位px
                formatter: '{b} : {c} ({d}%)', // a=>series.name b=>series.data.name c=>series.data.value, d=>占比
              }
            }
          }
        }]
      })

      echartsBar.setOption({
        title: {
          text: '求职者分布图', // 图标题
        },
        color: ['purple', 'red'], // 柱图调色盘
        xAxis: {
          data: [
            {
              value: '南京',
              textStyle: {
                color: 'skyblue',
                fontSize: 16
              }
            },
            {
              value: '上海',
              textStyle: {
                color: 'orange',
                fontSize: 16
              }
            },
            {
              value: '杭州',
              textStyle: {
                color: 'yellowgreen',
                fontSize: 16
              }
            },
            {
              value: '北京',
              textStyle: {
                color: 'pink',
                fontSize: 16
              }
            }
          ]
        },
        yAxis: {}, // 必须
        legend: {
          data: ['男', '女']
        },
        series: [
          {
            name: '男',
            type: 'bar', // 图类型-饼图
            data: [100, 60, 84, 66], // 饼图数据格式
            itemStyle: {
              normal: {
                label: {
                  show: true, // 显示对应文字
                  position: 'top', // 在上方显示
                  textStyle: { // 文字样式
                    color: 'purple',
                    fontSize: 16
                  }
                }
              }
            }
          },
          {
            name: '女',
            type: 'bar', // 图类型-饼图
            data: [135, 63, 70, 22], // 饼图数据格式
            itemStyle: {
              normal: {
                label: {
                  show: true, // 显示对应文字
                  position: 'top', // 在上方显示
                  textStyle: { // 文字样式
                    color: 'red',
                    fontSize: 16
                  }
                }
              }
            }
          }
        ]
      })

      echartsLine.setOption({
        title: {
          text: '求职者分布图', // 图标题
        },
        color: ['purple', 'red'], // 柱图调色盘
        xAxis: {
          data: [
            {
              value: '湖北',
              textStyle: {
                color: 'skyblue',
                fontSize: 16
              }
            },
            {
              value: '北京',
              textStyle: {
                color: 'orange',
                fontSize: 16
              }
            },
            {
              value: '上海',
              textStyle: {
                color: 'yellowgreen',
                fontSize: 16
              }
            },
            {
              value: '杭州',
              textStyle: {
                color: 'pink',
                fontSize: 16
              }
            }
          ]
        },
        yAxis: {}, // 必须
        legend: {
          data: ['男', '女']
        },
        series: [
          {
            name: '男',
            type: 'line', // 图类型-折线图
            data: [100, 60, 84, 66], // 折线图数据格式
            itemStyle: {
              normal: {
                label: {
                  show: true, // 显示对应文字
                  position: 'top', // 在上方显示
                  textStyle: { // 文字样式
                    color: 'purple',
                    fontSize: 16
                  }
                }
              }
            }
          },
          {
            name: '女',
            type: 'line', // 图类型-折线图
            data: [135, 63, 70, 22], // 折线图数据格式
            itemStyle: {
              normal: {
                label: {
                  show: true, // 显示对应文字
                  position: 'top', // 在上方显示
                  textStyle: { // 文字样式
                    color: 'red',
                    fontSize: 16
                  }
                }
              }
            }
          }
        ]
      })
    }
  },
  mounted() {
    this.initEcharts()
  }
}
</script>
<style scoped>
.echarts {
  display: inline-block;
  width: 700px;
  height: 500px;
  margin-left: 100px;
  /* border: 1px solid #c0c0c0; */
}
</style>
