<template>
  <div>
    <!-- 新增按钮 -->
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchName" placeholder="请输入角色名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="searchRole">搜索</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addNewItem">新增角色</el-button>
      </el-col>
    </el-row>
    <el-dialog
        :visible.sync="dialogVisible"
        width="50%"
        :title="handleType === 'add' ? '添加角色' : '修改角色'">
      <el-form :model="form" label-width="80px">
        <el-form-item label="角色名">
          <el-input v-model="form.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <!--        <el-form-item label="详情">
                  <el-input v-model="form.detail" autocomplete="off"></el-input>
                </el-form-item>-->
        <!--        <el-form-item label="类型">
                  <el-input v-model="form.type" autocomplete="off"></el-input>
                </el-form-item>-->

        <el-tree
            title="菜单"
            :data="menuConfig"
            show-checkbox
            default-expand-all
            node-key="permission"
            ref="tree"
            highlight-current
            :props="defaultProps">
        </el-tree>
        <el-button @click="resetChecked">清空</el-button>
      </el-form>
      <template slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </el-dialog>
    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;">
      <el-table-column
          fixed
          prop="roleId"
          label="roleId">
      </el-table-column>
      <el-table-column
          fixed
          prop="roleName"
          label="角色名">
      </el-table-column>
      <!--      <el-table-column
                fixed
                prop="detail"
                label="详情">
            </el-table-column>-->
      <!--      <el-table-column
                fixed
                prop="type"
                label="角色类型">
            </el-table-column>-->
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateRole(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="删除">
        <template v-slot="scope">
          <el-button @click="deleteRole(scope.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹出输入框（模态框） -->
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="totalItems"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>

import {addRole, deleteRole, getRoleList, updateRole} from "@/pages/role/role";
import menuConfig from "@/pages/menuConfig/menuConfig.json"

export default {
  name: "RoleManagement",
  data() {
    return {
      tableData: [], // 用于存储当前页的数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的数据项数
      totalItems: 0, // 总数据项数，用于分页组件显示总页数
      dialogVisible: false, // 是否显示对话框
      searchName: '',
      handleType: '',
      form: {
        roleId: '',
        roleName: '',
        permissions: []
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      menuConfig: [],
      menuItems: [],
    }
  },
  created() {
    this.fetchData();
    this.getMenuConfigTree();
  },
  methods: {
    fetchData() {
      getRoleList({
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
      }).then(response => {
        if (response.data.code === 200) {
          this.tableData = response.data.rows;
          this.totalItems = response.data.total;
        }
      });
    },
    getMenuConfigTree() {
      const permissions = localStorage.getItem('permissions');
      this.menuConfig = this.filterMenuByPermissions(menuConfig, permissions);
      console.log("当前角色能编辑的菜单:" + this.menuConfig)
    },
    // 处理拖拽开始事件
    handleDragStart(node) {
      console.log('drag start', node);
    },

// 处理拖拽进入事件
    handleDragEnter(draggingNode, dropNode) {
      console.log('tree drag enter: ', dropNode.label);
    },

// 处理拖拽离开事件
    handleDragLeave(draggingNode, dropNode) {
      console.log('tree drag leave: ', dropNode.label);
    },

// 处理拖拽过程中事件
    handleDragOver(draggingNode, dropNode) {
      console.log('tree drag over: ', dropNode.label);
    },

// 处理拖拽结束事件
    handleDragEnd(draggingNode, dropNode, dropType) {
      console.log('拖拽结束事件 dropType', dropType);
      console.log('拖拽结束事件 拖的节点', draggingNode.label);
      console.log('拖拽结束事件 拖的节点 之前的前面的节点', dropNode.label);
      console.log('拖拽结束事件 拖的节点 之前的前面的节点', dropNode.label);
      console.log('拖拽结束事件 拖的节点 parentId', draggingNode.parentId);
      console.log('拖拽结束事件 拖的节点 parentId', draggingNode.parentId);

      if (draggingNode.data.parentId != draggingNode.data.parentId) {
        this.getMenuConfigTree();
      }
    },
    // 处理拖拽放置事件
    handleDrop(draggingNode, dropNode, dropType) {
      console.log('tree drop: ', dropNode.label, dropType);
    },

    allowDrag(draggingNode) {
      return draggingNode.data.children.length == 0;
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    //弹出表单
    addNewItem() {
      this.dialogVisible = true;
      this.handleType = 'add';
      this.form = {
        roleId: '',
        roleName: '',
        type: '',
        title: '',
        detail: '',
        permissions: [],
      };
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      });
    },
    submitForm() {
      this.form.permissions = [];
      this.$refs.tree.getCheckedKeys().forEach(item => {
        this.form.permissions.push(item);
      });
      if (this.handleType === 'add') {
        addRole({
          data: this.form,
        }).then(response => {
          if (response.data.code === 200) {
            this.dialogVisible = false;
            alert("添加角色成功");
            this.fetchData();
            this.form = []
          }
        })
      } else if (this.handleType === 'update') {
        updateRole({
          data: this.form,
        }).then(response => {
          if (response.data.code === 200) {
            this.dialogVisible = false;
            this.fetchData();
          }
        })
      }
    },
    searchRole() {
      getRoleList({
        data: {'name': this.searchName},
      }).then(response => {
        this.tableData = response.data.data.list;
      })
    },
    updateRole(row) {
      this.dialogVisible = true;
      this.handleType = 'update';
      this.form = row;
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(row.permissions);
      });
    },
    deleteRole(row) {
      if (confirm("确认删除？")) {
        deleteRole({
          data: {'roleId': row.roleId},
        }).then(response => {
          if (response.data.code === 200) {
            alert("删除成功");
            this.fetchData();
          }
        })
      }
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    //只显示当前角色能编辑的菜单
    filterMenuByPermissions(menu, permissions) {
      return menu.filter(item => {
        if (permissions.includes(item.permission) || item.children.length > 0) {
          if (item.children) {
            item.children = item.children.filter(child => permissions.includes(child.permission));
          }
          return true;
        }
      });
    }
  }
}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
