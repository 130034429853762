import {sendJsonPostRequest} from '@/request/http';

export function companyPage(config) {
    config.url = '/company/page';
    return sendJsonPostRequest(config);
}

export function addCompany(config) {
    config.url = '/company/save';
    return sendJsonPostRequest(config);
}

export function updateCompany(config) {
    config.url = '/company/update';
    return sendJsonPostRequest(config);
}

