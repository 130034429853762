<template>
  <div>
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchCompanyName" placeholder="请输入公司名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="searchCompany">搜索公司</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="restSearch">重置搜索</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addCompanyJob" style="text-align: center">新增职位</el-button>
      </el-col>
    </el-row>
    <el-dialog
        title="删除职位"
        :visible.sync="delDialogVisible"
        width="30%">
      <span>确认删除职位信息</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="delDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="deleteCompanyJob">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :title="this.handleType === 'add' ? '新增职位' : '修改公司职位'"
        :visible.sync="dialogVisible"
        width="50%">
      <el-form ref="form" :model="form" label-width="7rem">
        <el-form-item label="公司名">
          <template #label>
            <span style="color: red">*</span> 公司
          </template>
          <el-select v-model="form.companyId" placeholder="请选择">
            <el-option
                v-for="item in companyDropDownList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位名">
          <template #label>
            <span style="color: red">*</span> 职位名
          </template>
          <el-input v-model="form.jobName" placeholder="职位名"></el-input>
        </el-form-item>
        <el-form-item label="工资待遇">
          <template #label>
            <span style="color: red">*</span> 工资待遇
          </template>
          <el-input-number v-model="form.salaryMin" controls-position="right" :min="1000"
                           :max="10000000" :step="500" step-strictly></el-input-number>
          <span style="margin: 0 1rem">至</span>
          <el-input-number v-model="form.salaryMax" controls-position="right" :min="2000"
                           :max="10000000" :step="500" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item label="招聘人数">
          <template #label>
            <span style="color: red">*</span> 招聘人数
          </template>
          <el-input-number v-model="form.invitePeople" :min="1" :max="10000"
                           label="描述文字"></el-input-number>
        </el-form-item>
        <el-form-item label="社保待遇">
          <template #label>
            <span style="color: red">*</span> 社保待遇
          </template>
          <el-input v-model="form.socialSecurity" placeholder="五险一金"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <template #label>
            <span style="color: red">*</span> 联系电话
          </template>
          <el-input required v-model="form.phone"></el-input>
        </el-form-item>

        <el-form-item label="工作城市">
          <template #label>
            <span style="color: red">*</span> 工作城市
          </template>
          <el-input v-model="form.city" placeholder="湖北"></el-input>
        </el-form-item>

        <el-form-item label="工作时间">
          <template #label>
            <span style="color: red">*</span> 工作时间
          </template>
          <el-input v-model="form.businessHours" placeholder="9:00-18:00"></el-input>
        </el-form-item>
        <el-form-item label="行业类型：">
          <template #label>
            <span style="color: red">*</span> 行业类型：
          </template>
          <el-select v-model="form.jobType" placeholder="请选择">
            <el-option
                v-for="item in jobTypeList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;margin-top: 1rem;">
      <el-table-column
          fixed
          prop="companyName"
          label="公司名">
      </el-table-column>
      <el-table-column
          fixed
          prop="city"
          label="工作城市">
      </el-table-column>
      <el-table-column
          fixed
          prop="jobName"
          label="工作名称">
      </el-table-column>
      <el-table-column
          fixed
          prop="salary"
          label="工资待遇">
      </el-table-column>
      <el-table-column
          fixed
          prop="invitePeople"
          label="招聘人数">
      </el-table-column>
      <el-table-column
          fixed
          prop="phone"
          label="联系电话">
      </el-table-column>
      <el-table-column
          fixed
          prop="createTime"
          label="发布时间">
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <el-tag v-if="scope.row.activate === 1" type="info">已下架</el-tag>
          <el-tag v-else type="success">发布中</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="上架、下架">
        <template v-slot="scope">
          <el-button @click="applyJob(scope.row)" type="primary">{{ scope.row.activate == 0 ? '下架' : '上架' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateCompany(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <!--      <el-table-column
                label="删除">
              <template v-slot="scope">
                <el-button @click="showDeleteCompanyJob(scope.row)" type="danger">删除
                </el-button>
              </template>
            </el-table-column>-->
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>


import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "CompanyJobManagement",
  data() {
    return {
      jobTypeList: [],
      companyDropDownList: [],
      tableData: [], // 用于存储当前页的数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的数据项数
      total: 0, // 总数据项数，用于分页组件显示总页数
      dialogVisible: false,
      handleType: '',
      searchCompanyName: '',
      form: {},
      num: 1,
      delDialogVisible: false,
      delId: null
    }
  },
  created() {
    this.fetchData();
    this.getCompanyDropDownList();
    this.getDictMap();
  },
  methods: {
    getDictMap() {
      this.jobTypeList = JSON.parse(localStorage.getItem('jobTypeList'));
    },
    showDeleteCompanyJob(row) {
      this.delId = row.id;
      this.delDialogVisible = true;
    },
    applyJob(row) {
      //上下架
      sendJsonPostRequest({
        url: '/companyJob/update',
        data: {'id': row.id, 'activate': 1 - row.activate},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.fetchData();
        } else {
          console.log(response.data.response)
        }
      })
    },
    getCompanyDropDownList() {
      sendJsonPostRequest({
        url: '/company/dropDownList'
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.companyDropDownList = response.data.data;
        } else {
          console.log(response.data.responseMessage);
        }
      })
    }
    ,
    fetchData() {
      sendJsonPostRequest({
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize},
        url: '/companyJob/page'
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        } else {
          console.log(response.data.responseMessage);
        }
      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    }
    ,
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    }
    ,
    addCompanyJob() {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'add';
      // 重置表单数据
      this.form = {
        invitePeople: 1,
        salaryMin: 1000,
        salaryMax: 2000
      };
    }
    ,
    submitForm() {
      if (this.handleType === 'add') {
        sendJsonPostRequest({
          url: '/companyJob/add',
          data: this.form,
        }).then(response => {
          if (response.data.responseCode === 200) {
            this.dialogVisible = false;
            this.fetchData();
          } else {
            console.log(response.data.message);
          }
        })
      } else if (this.handleType === 'update') {
        sendJsonPostRequest({
          url: '/companyJob/update',
          data: this.form,
        }).then(response => {
              if (response.data.responseCode === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.responseMessage);
              }
            }
        )
      }
    }
    ,
    deleteCompanyJob() {
      sendJsonPostRequest({
        url: '/companyJob/update',
        data: {'id': this.delId, expire: 1},
      })
          .then(response => {
                if (response.data.responseCode === 200) {
                  this.$message({
                    message: '删除成功',
                    type: 'warning'
                  });
                  this.delDialogVisible = false;
                  this.fetchData();
                }
              }
          )
    }
    ,
    updateCompany(row) {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'update';
      // 重置表单数据
      this.form = Object.assign({}, row);
    }
    ,
    searchCompany() {
      sendJsonPostRequest({
        url: '/companyJob/page',
        data: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          companyName: this.searchCompanyName,
        },
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.tableData = response.data.data.records;
        }
      })
    }
    ,
    restSearch() {
      this.searchCompanyName = '';
    }
    ,
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    }
    ,
    goCompanyJobDetail(id) {
      this.$router.push({path: '/appMain/companyJobDetail', query: {id: id}})
    }
  }
}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
