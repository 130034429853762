import {sendJsonPostRequest} from "@/request/http";

export function updateRole(config) {
    config.url = '/system/role/edit';
    return sendJsonPostRequest(config);
}

export function deleteRole(config) {
    config.url = '/system/role/remove';
    return sendJsonPostRequest(config);
}

export function addRole(config) {
    config.url = '/system/role/add';
    return sendJsonPostRequest(config);
}

export function getRoleList(config) {
    config.url = '/system/role/list';
    return sendJsonPostRequest(config);
}
