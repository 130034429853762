import axios from 'axios';
import qs from 'qs';
//定义一个通用发post json请求的方法
/*export function sendJsonPostRequest(config) {
    const token = localStorage.getItem('token');
    return axios({
        method: 'post',
        baseURL: process.env.VUE_APP_API_BASE_URL,
        url: config.url,
        data: config.data || {},
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': `Bearer ${token}`
        }
    }).catch(err => {
        console.log(err);
    });
}*/
export function sendJsonPostRequest(config) {
    const token = localStorage.getItem('token');
    let url = config.url;
    return axios({
        method: 'post',
        baseURL: process.env.VUE_APP_API_BASE_URL,
        url: url,
        data: config.data || {},
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Authorization': `Bearer ${token}`
        }
    }).catch(err => {
        console.log(err);
    });
}

export function sendFormPostRequest(config) {
    const token = localStorage.getItem('token');
    return axios({
        method: 'post',
        baseURL: process.env.VUE_APP_API_BASE_URL,
        url: config.url,
        data: qs.stringify(config.data || {}),
        timeout: 10000,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Authorization': `Bearer ${token}`
        }
    }).catch(err => {
        console.log(err);
    });
}


