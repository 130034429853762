<template>
  <div>
    <el-row>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </el-col>
      <el-col :span="19" style="text-align: center;">
        <h2>自动保存填写信息</h2>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form label-position="left" label-width="auto" :model="form" ref="form">
      <el-row :gutter="50">
        <el-col :span="5">
          <el-form-item label="姓名:">
            <template #label>
              <span style="color: red">*</span> 姓名:
            </template>
            <el-input v-model="form.realName" placeholder="姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="手机号码:">
            <template #label>
              <span style="color: red">*</span> 手机号码:
            </template>
            <el-input v-model="form.phone" placeholder="手机号码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="出生日期:">
            <template #label>
              <span style="color: red">*</span> 出生日期:
            </template>
            <el-date-picker
                value-format="yyyy-MM-dd 00:00:00"
                v-model="form.birthday"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="在职/离职">
            <template #label>
              <span style="color: red">*</span> 在职/离职:
            </template>
            <el-select v-model="form.onJob" placeholder="在职/离职">
              <el-option
                  v-for="item in onJobList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="5">
          <el-form-item label="专业">
            <template #label>
              <span style="color: red">*</span> 专业:
            </template>
            <el-select v-model="form.professional" placeholder="专业">
              <el-option
                  v-for="item in professionalList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="性别:">
            <template #label>
              <span style="color: red">*</span> 性别:
            </template>
            <el-select v-model="form.sex" placeholder="性别：">
              <el-option
                  v-for="item in sexDictList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="最高学历:">
            <template #label>
              <span style="color: red">*</span> 最高学历:
            </template>
            <el-select v-model="form.education" placeholder="最高学历">
              <el-option
                  v-for="item in educationList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="全日制:">
            <el-radio v-model="form.fullTime" checked label="全日制">是</el-radio>
            <el-radio v-model="form.fullTime" label="非全日制">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="5">
          <el-form-item label="期望月薪:">
            <template #label>
              <span style="color: red">*</span> 期望月薪:
            </template>
            <el-input-number :min="1000" :max="10000000" controls-position="right" v-model="form.minSalary" :step="500"
                             step-strictly></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="到岗时间:">
            <template #label>
              <span style="color: red">*</span> 最快到岗时间:
            </template>
            <el-select v-model="form.arrivalTime" placeholder="到岗时间">
              <el-option
                  v-for="item in arrivalTimeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="用户类型:">
            <template #label>
              <span style="color: red">*</span> 用户类型:
            </template>
            <el-select v-model="form.userType" placeholder="用户类型:">
              <el-option
                  v-for="item in userTypeList"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="工作经验:">
            <template #label>
              <span style="color: red">*</span> 工作经验/年:
            </template>
            <el-input-number :min="0" :max="100" controls-position="right" v-model="form.workingYears" :step="0.5"
                             step-strictly></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex" justify="center">
        <el-col :span="3">
          <el-button type="primary" size="small" @click="addWorkExperience">新增工作经历</el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="submitForm">提交</el-button>
        </el-col>
      </el-row>
      <el-row v-for="(experience, index) in workExperiences" :key="index" :gutter="0" style="margin-top: 1rem;">
        <el-col :span="4">
          <el-form-item label="公司名:">
            <template #label>
              <span style="color: red">*</span> 公司名:
            </template>
            <el-input v-model="experience.experienceCompanyName" placeholder="公司名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="入职:">
            <template #label>
              <span style="color: red">*</span> 入职:
            </template>
            <el-date-picker v-model="experience.experienceStartTime" value-format="yyyy-MM-dd 00:00:00" type="date"
                            placeholder="日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="离职:">
            <template #label>
              <span style="color: red">*</span> 离职:
            </template>
            <el-date-picker size="small" value-format="yyyy-MM-dd 00:00:00" v-model="experience.experienceEndTime"
                            type="date"
                            placeholder="日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="薪资:">
            <template #label>
              <span style="color: red">*</span> 月薪:
            </template>
            <el-input :min="1000" :max="10000000" v-model="experience.experienceSalary" type="number" placeholder="薪资"
                      :step="500"
                      step-strictly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="证明人姓名:">
            <template #label>
              <span style="color: red">*</span> 证明人:
            </template>
            <el-input v-model="experience.experiencePeopleName" placeholder="姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="证明人电话:">
            <template #label>
              <span style="color: red">*</span> 电话:
            </template>
            <el-input v-model="experience.experiencePeoplePhone" placeholder="电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="text-align: center;">
          <el-button type="danger" size="small" @click="removeWorkExperience(index)">删除</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "AddWxUserManagement",
  data() {
    return {
      userTypeList: [],
      professionalList: [],
      educationList: [],
      arrivalTimeList: [],
      workExperiences: [
        {
          experienceCompanyName: '',
          experienceStartTime: '',
          experienceEndTime: '',
          experienceSalary: 1000,
          experiencePeopleName: '',
          experiencePeoplePhone: ''
        }
      ],
      form: {
        userType: "3",
        realName: '',
        age: 0,
        sex: 1,
        fullTime: '全日制',
        minSalary: 1000,
        workingYears: 1,
        education: '1',
        professional: '1',
        arrivalTime: '0',
        onJob: 0
      },
      sexDictList: [
        {
          dictValue: 0,
          dictLabel: '女'
        },
        {
          dictValue: 1,
          dictLabel: '男'
        },
        {
          dictValue: 2,
          dictLabel: '未知'
        }
      ],
      onJobList: [
        {
          label: '在职',
          value: 1
        },
        {
          label: '离职',
          value: 0
        }
      ],
    }
  },
  methods: {
    goBack() {
      this.$router.replace({path: '/appMain/wxUser'});
    },
    addWorkExperience() {
      const lastExperience = this.workExperiences[this.workExperiences.length - 1];
      const isEmpty = Object.values(lastExperience).some(value => !value);
      if (isEmpty) {
        this.$message.error("请先填写完整当前的工作经历信息");
        return;
      }
      this.workExperiences.push({
        experienceCompanyName: '',
        experienceStartTime: '',
        experienceEndTime: '',
        experienceSalary: '',
        experiencePeopleName: '',
        experiencePeoplePhone: ''
      });
    },
    removeWorkExperience(index) {
      this.workExperiences.splice(index, 1);
      this.saveDraft();
    },
    submitForm() {
      const lastExperience = this.workExperiences[this.workExperiences.length - 1];
      const isEmpty = Object.values(lastExperience).some(value => !value);
      if (isEmpty) {
        this.$message.error("请先填写完整当前的工作经历信息");
        return;
      }
      this.form.workExperiences = this.workExperiences;
      sendJsonPostRequest({
        url: '/sysWxUser/save',
        data: this.form,
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.$message.success('添加成功');
          this.$router.push({path: '/appMain/wxUser'});
          this.clearDraft();
          this.resetForm();
        } else {
          this.$message.error('添加失败');
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    getDictMap() {
      this.professionalList = JSON.parse(localStorage.getItem('professionalList'));
      this.educationList = JSON.parse(localStorage.getItem('educationList'));
      this.arrivalTimeList = JSON.parse(localStorage.getItem('arrivalTimeList'));
      this.userTypeList = JSON.parse(localStorage.getItem('userTypeList'));
    },
    // 保存草稿到 localStorage
    saveDraft() {
      //自动计算年龄
      if (this.form.birthday) {
        const birthdayDate = new Date(this.form.birthday);
        const today = new Date();
        const age = today.getFullYear() - birthdayDate.getFullYear();
        this.form.age = age;
      }
      localStorage.setItem('formDraft', JSON.stringify(this.form));
      localStorage.setItem('workExperiencesDraft', JSON.stringify(this.workExperiences));
    },
    // 清除草稿
    clearDraft() {
      localStorage.removeItem('formDraft');
      localStorage.removeItem('workExperiencesDraft');
    },
    // 从 localStorage 恢复草稿
    loadDraft() {
      const formDraft = localStorage.getItem('formDraft');
      const workExperiencesDraft = localStorage.getItem('workExperiencesDraft');
      if (formDraft) {
        this.form = JSON.parse(formDraft);
      }
      if (workExperiencesDraft) {
        this.workExperiences = JSON.parse(workExperiencesDraft);
      }
    }
  },
  created() {
    this.getDictMap();
    this.loadDraft();
  },
  watch: {
    // 监听 form 和 workExperiences 的变化并保存草稿
    form: {
      deep: true,
      handler() {
        this.saveDraft();
      }
    },
    workExperiences: {
      deep: true,
      handler() {
        this.saveDraft();
      }
    }
  }
}
</script>

<style scoped>

</style>
