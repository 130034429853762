<template>
  <el-row>
    <el-container style="height: 100vh; border: 1px solid #eee;overflow: hidden">
      <el-aside width="12%" style="overflow: hidden">
        <el-menu style="width: 100%;overflow: auto">
          <el-menu-item index="-1" @click="goToPage('/appMain/home')"><span class="el-icon-s-home"
                                                                            style="font-size: 1.5rem;">看板</span>
          </el-menu-item>
          <template v-for="(item,index) in menuItems">
            <template v-if="item.children.length === 0">
              <el-menu-item :index="index.toString()" @click="goToPage(item.route)" :key="index">
                <i :class="item.icon"></i>{{ item.name }}
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu :index="index.toString()" :key="index">
                <template slot="title"><i :class="item.icon"></i>{{ item.name }}</template>
                <template v-for="(item1,index1) in item.children">
                  <el-menu-item :index="index1.toString() + '01'" @click="goToPage(item1.route)" :key="index1">
                    <i :class="item1.icon"></i>{{ item1.name }}
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
          </template>
        </el-menu>
      </el-aside>
      <el-container style="height: 100%">
        <el-header>
          <el-row>
            <el-col :span="12" style="text-align: center;font-weight: bold;font-size: 1rem;color: white;">
              湖北邻军人力资源大数据管理平台
            </el-col>
            <el-col :span="12">
              <el-row type="flex" jentify="end" align="middle">
                <el-col :span="2" style="display: flex;justify-content: center;align-items: center;">
                  <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                </el-col>
                <el-col :span="1"
                        style="display: flex;color: white;justify-content: center;align-items: center;height: 100%;">
                  用户
                </el-col>
                <el-col :span="1" style="display: flex;color: white;justify-content: center;align-items: center;">
                  |
                </el-col>
                <el-col :span="5"
                        style="font-weight: bold;color: white;display: flex;justify-content: center;align-items: center;">
                  <template>
                    {{ currentTime }}
                  </template>
                </el-col>
                <el-col :span="1" style="display: flex;color: white;justify-content: center;align-items: center;">
                  |
                </el-col>
                <el-col :offset="3" :span="2"
                        style="cursor: pointer;font-size: 1rem;display: flex;justify-content: center;align-items: center;">
                  <span style="color: white" @click="exitSystem" class="el-icon-switch-button"></span>
                  <span style="color: white;" @click="exitSystem">退出</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-header>
        <el-main style="overflow: hidden">
          <router-view></router-view>
        </el-main>

      </el-container>
    </el-container>
  </el-row>
</template>

<script>
import menuConfig from "@/pages/menuConfig/menuConfig.json"
import {sendJsonPostRequest} from "@/request/http";

export default {
  data() {
    return {
      menuItems: [],
      userName: '未登录',
      currentTime: '',
      menuJsonConfig: []
    }
  },
  created() {
    // 从后端取取用户权限字符串
    const permissions = localStorage.getItem('permissions');
    this.userName = localStorage.getItem('userName');
    // 根据权限生成菜单项
    this.generateMenu(permissions, menuConfig);
    //缓存数据字典 todo优化
    this.cacheDictList();
  },
  methods: {
    cacheDictList() {
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'professional'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("professional", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/list',
        data: {'dictType': 'professional'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("professionalList", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/list',
        data: {'dictType': 'education'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("educationList", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/list',
        data: {'dictType': 'arrivalTime'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("arrivalTimeList", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'arrivalTime'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("arrivalTime", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'education'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("education", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'userType'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("userType", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/list',
        data: {'dictType': 'userType'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("userTypeList", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'companyNature'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("companyNature", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'industryType'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("industryType", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'sex'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("sex", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
      sendJsonPostRequest({
        url: '/sysDict/list',
        data: {'dictType': 'jobType'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("jobTypeList", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });

      sendJsonPostRequest({
        url: '/sysDict/map',
        data: {'dictType': 'arrivalTime'},
      }).then(response => {
        if (response.data.responseCode === 200) {
          localStorage.setItem("arrivalTime", JSON.stringify(response.data.data));
        } else {
          console.log(response.data.message);
        }
      });
    },
    generateMenu(permissions, menuJsonConfig) {
      const filteredMenu = menuJsonConfig.filter(item => {
            if (permissions.includes(item.permission)) {
              if (item.children.length > 0) {
                item.children = item.children.filter(child => permissions.includes(child.permission));
              }
              return true;
            } else {
              if (item.children.length > 0) {
                item.children = item.children.filter(child => permissions.includes(child.permission));
                if (item.children.length > 0) {
                  return true;
                }
              }
            }
          }
      );
      filteredMenu.sort((a, b) => a.sort - b.sort);
      this.menuItems = filteredMenu;
    },
    goToPage(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    exitSystem() {
      this.$message({
        message: '退出成功',
        type: 'success',
      });
      localStorage.clear();
      this.$router.push('/login');
    },
    updateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  }
}
</script>
<style>
.el-header {
  background-color: #487EEA;
  color: #333;
  line-height: 60px;
  text-align: end;
}

.el-aside {
  color: #333;
  background-color: rgb(113, 137, 167);
}

.fc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  color: #ffffff
}

.fc_1, .fc_2, .fc_3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc_head {
  margin: 15px;
}


</style>
