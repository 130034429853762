<template>
<span>
  todo短信信息管理
</span>
</template>

<script>
export default {
  name: "SmsMessageTemplateManagement"
}
</script>

<style scoped>

</style>
