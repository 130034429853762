<template>
<span>
  编辑求职者
</span>
</template>

<script>
export default {
  name: "EditWxUserManagement"
}
</script>

<style scoped>

</style>
