<template>
  <span>{{ company.name }}</span>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "CompanyDetailManagement",
  data() {
    return {
      company: {}
    }
  },
  methods: {
    getCompanyDetail() {
      sendJsonPostRequest({
        url: '/company/detail',
        data: {'id': this.$route.query.id},
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.company = response.data.data
        }
      })
    }
  },
  created() {
    this.getCompanyDetail();
  }
}
</script>

<style scoped>

</style>
