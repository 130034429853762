<template>
  <div>
    <el-row type="flex" :gutter="20" class="row-style">
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="3">
        <el-input v-model="searchUserName" placeholder="请输入用户名"></el-input>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="searchUser">搜索用户</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="restSearch">重置搜索</el-button>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
        <el-button type="primary" @click="addUser" style="text-align: center">新增用户</el-button>
      </el-col>
    </el-row>
    <el-dialog
        :title="this.handleType === 'add' ? '新增用户' : '修改用户'"
        :visible.sync="dialogVisible"
        width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="用户名">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="form.phonenumber"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="用户类型">
          <el-select v-model="form.userType" placeholder="用户类型">
            <el-option
                key="1"
                label="普通用户"
                value="1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex" placeholder="性别">
            <el-option
                key="0"
                label="男"
                value="0">
            </el-option>
            <el-option
                key="1"
                label="女"
                value="1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色类型">
          <el-select v-model="form.roleId" placeholder="角色类型">
            <el-option
                v-for="item in rolesList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <el-table
        :data="tableData" :row-class-name="tableRowClassName"
        style="width: 100%;overflow: scroll;">
      <el-table-column
          fixed
          prop="userName"
          label="用户名">
      </el-table-column>
      <el-table-column
          fixed
          prop="phonenumber"
          label="手机号码">
      </el-table-column>
      <el-table-column
          fixed
          prop="email"
          label="邮箱">
      </el-table-column>
      <el-table-column
          label="编辑">
        <template v-slot="scope">
          <el-button @click="updateUser(scope.row)" type="primary">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="删除">
        <template v-slot="scope">
          <el-button @click="deleteUser(scope.row)" type="danger">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange">
    </el-pagination>
  </div>
</template>

<script>
//导入crud 请求方法
import {addUser, deleteUser, getUserList, roleSelectList, updateUser} from '@/pages/user/user'

export default {
  name: "UserManagement",
  data() {
    return {
      tableData: [], // 用于存储当前页的数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的数据项数
      total: 0, // 总数据项数，用于分页组件显示总页数
      dialogVisible: false,
      handleType: '',
      searchUserName: '',
      form: {
        userName: '',
        password: '',
        phonenumber: '',
        userType: '',
        roleId: '',
        sex: '',
        email: ''
      },
      rolesList: [],
    }
  },
  created() {
    this.fetchData(); // 在组件创建时获取第一页数据
    this.roleSelectList();
  },
  methods: {
    fetchData() {
      getUserList({
        data: {'pageNum': this.currentPage, 'pageSize': this.pageSize, userType: 1},
      }).then(response => {
        if (response.data.code === 200) {
          this.tableData = response.data.rows;
          this.total = response.data.total;
        } else {
          console.log(response.data.msg);
        }

      }).catch(error => {
        console.error("Error fetching data:", error);
      });
    },
    roleSelectList() {
      roleSelectList({}).then(response => {
        if (response.data.code == 200) {
          this.rolesList = response.data.rows;
        }
      })
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData(); // 当页码改变时重新获取数据
    },
    addUser() {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'add';
      // 重置表单数据
      this.form = {
        userId: '',
        password: '',
        userName: '',
        phonenumber: '',
        uerType: '',
        roleId: '',
        sex: '',
        email: ''
      };
    },
    submitForm() {
      if (this.handleType === 'add') {
        addUser({
          data: this.form,
        }).then(response => {
              if (response.data.code === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      } else if (this.handleType === 'update') {
        updateUser({
          data: this.form,
        }).then(response => {
              if (response.data.code === 200) {
                this.dialogVisible = false;
                this.fetchData();
              } else {
                console.log(response.data.message);
              }
            }
        )
      }
    },
    deleteUser(row) {
      if (confirm("确认删除？")) {
        deleteUser({
          data: {'userId': row.userId},
        })
            .then(response => {
                  if (response.data.code === 200) {
                    alert("删除成功");
                    this.fetchData();
                  }
                }
            )
      }
    },
    updateUser(row) {
      // 显示对话框以添加新项
      this.dialogVisible = true;
      this.handleType = 'update';
      // 重置表单数据
      this.form = {
        userId: row.userId,
        userName: row.userName,
        phonenumber: row.phonenumber,
        password: row.password,
        userType: row.userType,
        roleId: row.roleId,
        email: row.email,
        sex: row.sex
      };
    },
    searchUser() {
      getUserList({
        data: {'userName': this.searchUserName, userType: '1'},
      }).then(response => {
            this.tableData = response.data.rows;
          }
      )
    },
    restSearch() {
      this.searchUserName = '';
    },
    tableRowClassName({rowIndex}) {
      //tableRowClassName({row, rowIndex})
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      } else {
        return 'success-row';
      }
    }
  },

}
</script>


<style scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>
