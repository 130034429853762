<template>
  <div>
    <el-row>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <!-- 基本信息展示 -->
    <h3>基本信息</h3>
    <el-row :gutter="50">
      <el-col :span="5">姓名：{{ userDetails.realName }}</el-col>
      <el-col :span="5">联系电话：{{ userDetails.phone }}</el-col>
      <el-col :span="5">出生日期：{{ dateFormat(userDetails.birth) }}</el-col>
      <el-col :span="5">在职/离职：{{ userDetails.onJob ? '在职' : '离职' }}</el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="50">
      <el-col :span="5">专业：{{ professionalLabel }}</el-col>
      <el-col :span="5">性别：{{ sexLabel }}</el-col>
      <el-col :span="5">学历：{{ educationLabel }}</el-col>
      <el-col :span="5">全日制：{{ userDetails.fullTime }}</el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="50">
      <el-col :span="5">期望月薪：{{ userDetails.minSalary }}</el-col>
      <el-col :span="5">最快到岗时间：{{ arrivalTimeLabel }}</el-col>
      <el-col :span="5">用户类型：{{ userDetailsMap[userDetails.userType] }}</el-col>
      <el-col :span="5">工作经验/年：{{ userDetails.workingYears }}</el-col>
    </el-row>


    <el-divider></el-divider>
    <h3>工作经历</h3>
    <div v-for="(experience, index) in userDetails.experienceVos" :key="index" style="margin-bottom: 1rem;">
      <el-row :gutter="50">
        <el-col :span="5">公司名：{{ experience.experienceCompanyName }}</el-col>
        <el-col :span="5">入职日期：{{ dateFormat(experience.experienceStartTime) }}</el-col>
        <el-col :span="5">离职日期：{{ dateFormat(experience.experienceEndTime) }}</el-col>
        <el-col :span="5">月薪：{{ experience.experienceSalary }}</el-col>
      </el-row>
      <el-row :gutter="50" style="margin-top: 1rem;">
        <el-col :span="5">证明人：{{ experience.experiencePeopleName }}</el-col>
        <el-col :span="5">证明人电话：{{ experience.experiencePeoplePhone }}</el-col>
      </el-row>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import {sendJsonPostRequest} from "@/request/http";

export default {
  name: "UserDetail",
  data() {
    return {
      userDetailsMap: {},
      userDetails: {}
    };
  },
  methods: {
    dateFormat(str) {
      if (!str) return '';
      // 通过空格分割字符串，只取日期部分
      return str.split(' ')[0];
    },
    goBack() {
      this.$router.replace({path: '/appMain/wxUser'});
    },
    getUserDetails() {
      sendJsonPostRequest({
        url: '/sysWxUser/detail',
        data: {id: this.$route.query.id}
      }).then(response => {
        if (response.data.responseCode === 200) {
          this.userDetails = response.data.data;
        }
      })
    }
  },
  created() {
    this.userDetailsMap = JSON.parse(localStorage.getItem('userType'));
    this.getUserDetails();
  },
  computed: {
    sexLabel() {
      let item = JSON.parse(localStorage.getItem('sex'));
      return item[this.userDetails.sex];
    },
    educationLabel() {
      let item = JSON.parse(localStorage.getItem('education'));
      return item[this.userDetails.education];
    },
    arrivalTimeLabel() {
      let item = JSON.parse(localStorage.getItem('arrivalTime'));
      return item[this.userDetails.arrivalTime];
    },
    professionalLabel() {
      let item = JSON.parse(localStorage.getItem('professional'));
      return item[this.userDetails.professional];
    },
  }
};
</script>

<style scoped>

</style>
