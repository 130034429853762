<template>
  <div class="loginBody">

    <el-row type="flex" justify="center" align="middle" class="login-row">
      <el-col :span="24" class="login-col">
        <div class="loginText">
          <h2>湖北邻军人力资源平台</h2>
        </div>
        <div class="formData">
          <el-form ref="form" :model="loginForm">
            <el-form-item prop="username" style="margin-left: 0px;">
              <el-input
                  v-model="loginForm.username"
                  clearable
                  placeholder="请输入账号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-left: 0px">
              <el-input @keyup.enter.native="handleSubmit"
                        v-model="loginForm.password"
                        clearable
                        placeholder="请输入密码"
                        show-password></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="butt">
          <el-button type="primary" class="login" @click="handleSubmit">登录</el-button>
          <!--          <el-button class="shou">注册</el-button>-->
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import {loginJsonPostRequest} from "@/pages/login/login";
import md5 from 'js-md5';

export default {
  name: "LoginManagement",
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    handleSubmit() {
      if (this.loginForm.username == '' || this.loginForm.password == '') {
        this.$message.error('请输入账号和密码');
        return;
      }
      loginJsonPostRequest({
        data: {
          username: this.loginForm.username,
          password: md5(this.loginForm.password)
        },
      }).then(response => {
        if (response.data.responseCode !== 200) {
          this.$message({
            message: response.data.responseMessage,
            type: 'error'
          });
          return;
        }
        // 保存用户信息
        const userInfo = response.data.data;
        localStorage.setItem('userName', userInfo.username);
        localStorage.setItem('userId', userInfo.userId);
        localStorage.setItem('token', userInfo.token);
        localStorage.setItem('permissions', userInfo.authList);
        this.$router.push('/appMain/home');
        //todo 刷新页面 编辑权限菜单时 打开这个
        // location.reload();
      }).catch(error => {
        if (error.response.data.responseCode === 403)
          this.$message.error(error.response.data.responseMessage)
      });
    },
  }
};
</script>

<style scoped>
.loginBody {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url("~@/assets/login-background.jpg");
  background-size: 100% 100%;
  background-position: center center;
}

.loginText {
  margin-bottom: 20px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.butt {
  margin-top: 10px;
  text-align: center;
}

.shou {
  cursor: pointer;
  color: #606266;
}

/*.login-row {
  width: 100%;
}

.login-col {
  width: 20%;
  padding: 20px;
  box-sizing: border-box;
}*/
</style>

