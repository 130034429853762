import axios from "axios";

export function loginJsonPostRequest(config) {
    return axios({
        method: 'post',
        baseURL: process.env.VUE_APP_API_BASE_URL,
        url: '/user/login',
        data: config.data || {},
        timeout: 4000,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        }
    });
}
